import React from 'react';
import { H2, P } from '@dnb/eufemia/elements';
import Head from '@dnb/helmet';
import { Link } from '@dnb/link';
import { SimpleHeader } from '@dnb/header';
import FeedbackModule from '../../components/FeedbackModule';

function PageIndex() {
  return (
    <>
      <Head>
        <title>Web Framework demos</title>
      </Head>
      <SimpleHeader title="Feedback form demo (1/2)" />

      <H2>Lorem impsum</H2>

      <P bottom="1rem">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam consequat
        accumsan libero, in mattis nunc sollicitudin quis. Suspendisse potenti.
        Curabitur dapibus, quam nec tristique imperdiet, elit quam volutpat
        velit, a faucibus dui leo in nunc. Curabitur mi augue, vestibulum eu
        odio in, viverra semper dui. Quisque ut ornare libero, id porta nunc.
        Vestibulum laoreet auctor est quis ullamcorper. Nullam ut nunc sed
        tortor laoreet lobortis dignissim et nisi. Orci varius natoque penatibus
        et magnis dis parturient montes, nascetur ridiculus mus. Pellentesque
        vitae nunc sagittis, iaculis quam nec, pretium neque. Aliquam
        scelerisque facilisis enim, sed tempor sem.
      </P>

      <FeedbackModule
        feedbackId="1"
        feedbackQuestion="What do you think of this page?"
        likeButtonText="Like"
        dislikeButtonText="Dislike"
      />

      <Link to="/feedback-form-demo/page-2">Next example</Link>
    </>
  );
}

export default PageIndex;
